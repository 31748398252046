@import 'common';

html {
    color: #222;
    font-size: 16px;
    line-height: 1.2;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
}
@mixin contentArea {
    position:relative;
    max-width: 1300px;
    padding: 30px;
    margin: 0 auto;
    z-index: 1; /* over darkens */
}
.contentwrap {
    @include contentArea;
}

img {
    border:none;
}
a.logo {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    height:auto;
    padding: 8px 12px;
    h1 {
        font-size: 20px;
        margin: 0px;
    }
}
@mixin tint_overlay {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 0;
}

/** menu generic **/
nav {
    ul {
        list-style:none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        justify-content: space-between;
        box-sizing: border-box;
    }
    li {
        display: inline-block;
        box-sizing: border-box;
        a {
            display: block;
            color:inherit;
            text-decoration: none;
        }
    }
    &.slide-menu,
    &.featured {
        ul,
        li {
            display: block;
        }
    }
}

header a,
footer a,
.contact-details a,
.careers-block a {
    text-decoration: none;
    color: inherit;
}
header,
footer {
    color: #FFF;
    background-color: $light-grey;
}
.menu-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    font-weight: bold;
    background-color: $primary;
    padding: 2px 20px;
    padding-left: 0px;
    border-radius: 35px;
    width: 100%; /* in footer */
    max-width: 100%;
    box-sizing: border-box;
    li a {
        opacity: 0.5;
        padding: 16px 10px;
        transition: opacity 0.3s ease;
        &:hover {
            opacity: 1;
        }
    }
    &.main-menu { /* in header */
        display: flex;
        justify-content: flex-start;
        justify-content: space-evenly;
        flex-grow:1;
        width: max(50%, 600px);
        padding: 8px 15px;
        margin-left: auto;
        box-sizing: border-box;
        > ul {
            flex-grow: 1;
        }
    }
}
.main-menu { /* in footer */
    display: inline-flex;
    flex-wrap: wrap;
}

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
    p {
        position: relative;
        margin: 15px 0;
    }
    &:after { /* clear button float */
        content:"";
        display: block;
        width: 100%;
        height: 1px;
        clear: both;
    }
}
label.float,
input {
    height: 42px;
    line-height: 42px;
}
.error {
    color: $highlight-dark;
}
.js {
    .no-js {
        display: none;
    }
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font;
    color: $highlight;
    font-size: 18px;
    padding-top: 10px; /* for labels */
    width: 100%;
    background-color: $tint;
    border: 0px;
    border-radius: 20px;
    box-sizing: border-box;
}
.darken {
    position: relative;
    color: #FFF;
    background-color: $primary;
    &:before {
        @include tint_overlay;
        background-color: rgba($primary, 0.52);
        z-index: 1;
    }
    h1,h2,h3,h4 {
        color: inherit;
        &.orange {
            color: $highlight;
        }
    }
    .label,
    a {
        color: $highlight;
    }
    .label {
        font-weight: bold;
    }
    input,
    textarea {
        background-color: #FFF;
    }
}
.file {
    padding-left: 20px;
}
textarea {
    min-height: 160px;
}
label.float {
    position: absolute;
    left:0;
    color: $label;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    line-height: 1;
    padding-top: 5px;
    font-size: 0.5em;
}
form button {
    @include button;
    cursor: pointer;
    float: right;
}
.recaptcha-notice {
    display: inline-block;
    vertical-align: middle;
    color: inherit;
    margin-top: 14px;
    a {
        color: inherit;
    }
}

/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
}

/** common styles **/
.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-content: space-between;
    max-width: 100%;
}
.two-col {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    padding: 2% 0px;
    padding-bottom: 4%;
    box-sizing: border-box;
    > * {
        margin-top: 0px;
    }
}

/* services icons */
.services-icons {
    color: #FFF;
    @include gradient;
    nav li {
        width: 25%;
        padding: 20px 2%;
        text-align: center;
        .menu-icon {
            display: block;
            width: 100%;
            padding-top: 40%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .title {
            display: block;
            margin-top: 30px;
            transition: color 0.3s ease;
        }
        a:hover {
            .title {
                color: #000;
            }
        }
    }
}

/* careers-block */
.careers-block {
    padding: 2% 0px;
    overflow: hidden;
    .two-col {
        padding-bottom: 2%;
    }
    .home-icon {
        position: absolute;
        top: 50%;
        margin-top: -20vw;
        right: 20px;
        width: 40vw;
        height: 40vw;
        background-size: contain;
        background-position: center;
        z-index: 0;
    }
    h3 span {
        display: block;
        margin: 0.8em 0px;
        &.button {
            display: inline-block;
        }
    }
}
.file-upload-container {
    display: inline-block;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
}
a:hover {
    span.button {
        background-color: $secondary;
    }
}


/*============ header ============*/
header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    &:before { /* darken */
        @include tint_overlay;
        mix-blend-mode: multiply;
        z-index: 1;
    }
    &:after { /* stripes */
        content:"";
        display: block;
        position: absolute;
        bottom: 0px;
        right: -5%;
        width: 100%;
        height: 100%;
        background-image: url('../assets/stripes-fx.svg');
        background-size: 30% 100%;
        background-repeat: no-repeat;
        background-position: bottom right;
        z-index: 1;
        opacity: 0.5;
    }
    a.logo {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 300px;
        padding: 0px;
        max-width: 100%;
        z-index: 1;
    }
    &.has-graphic {
        padding-bottom: 10%;
        .page-title {
            padding-top: 6%;
        }
    }
    .contentwrap {
        width: 1360px;
        box-sizing: border-box;
        max-width: 100%;
        z-index: 2;
    }
}
.t-services {
    header {
        padding-bottom: 50px; /* icon overlap */
        &.has-graphic {
            padding-bottom: 50px;
        }
    }
}
@mixin fit-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
}
.page-graphic {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
        @include fit-image;
    }
}
.page-title {
    color: #FFF;
    font-size: 4em;
    padding-left: 51%;
    box-sizing: border-box;
}

/* menu styles above in generic */
#mobile-menu {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 45px;
    height: 45px;
    padding: 5px 8px;
    padding-top: 8px;
    background-color: $primary;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    z-index:100;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $highlight;
    margin: 6px 0;
    transition: all 0.5s ease;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}

/***** Slide menu ****/
.slide-menu {
    display:none;
    background: $primary;
    width:240px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -240px;
	overflow-y:scroll;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu .menu-title {
	padding: 1em;
	color:#fff;
	background: $highlight;
	margin:0;
	font-weight:bold;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	display: block;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid #030b16;
	padding: 18px;
	text-decoration:none;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	background: $secondary;
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #030b16;
    height: 54px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
/*============ footer ============*/
footer {
    .contentwrap {
        padding-top: 40px;
    }
    .abn {
        display: block;
        padding: 10px 20px;
    }
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 16px;
    padding: 5% 0px 3% 0px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: inherit;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}


/*============ home ============*/
.t-home header {
    min-height: 100vh;
    &.has-graphic {
        padding-bottom: 0px;
    }
}
/*  banner */
.banners-home {
    position:relative;
    height: 100vh;
    min-height: 900px;
    box-sizing: border-box;
    .banner-stage {
        position: relative;
        height: 100%;
        padding: 30px;
        padding-top: 100px; /* logo */
        padding-bottom: 300px; /* header menu + banner nav */
        box-sizing: border-box;
    }
    .contentwrap { /* header menu */
        position: absolute;
        bottom: 200px;
        left: 0px;
        right: 0px;
        width: 1360px;
        box-sizing: border-box;
    }
    .banner-navigation {
        display:none;
        position: absolute;
        bottom: 100px;
        left: 0px;
        right: 0px;
    }
}
a.banner {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 120px; /* logo */
    padding-bottom: 300px; /* header menu + banner nav */
    box-sizing: border-box;
    background-position:center;
    background-size:cover;
    z-index: 0;
    opacity: 0;
    &.active {
        z-index: 1;
        opacity: 1;
    }
    &:before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.2);
        mix-blend-mode: multiply;
        z-index: 1;
    }
}
.banner-content,
.banner-navigation {
    @include contentArea;
}
.banner-navigation-body {
    text-align: center;
    padding-left: 50%;
    box-sizing: border-box;
}
.banner-nav-item {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #FFF;
    border-radius: 50%;
    margin: 5px 10px;
    &.active {
        background-color: #FFF;
    }
    > span {
        display: none;
    }
}
.banner-content {
    display: flex; /* need to reorder tagline */
    font-size: 4em;
    text-shadow: -1px -1px 0px rgba(255,255,255,0.3), 1px -1px 0px rgba(255,255,255,0.3),
                 -1px 1px 0px rgba(255,255,255,0.3), 1px 1px 0px rgba(255,255,255,0.3);
    flex-direction: column;
    width: 1300px;
    max-width: 100%;
    box-sizing: border-box;
    > span {
        order: 1; /* push down */
        display: block;
        padding-left: 51%;
        font-weight: bold;
        line-height: 1.2;
        color: $primary;
        box-sizing: border-box;
    }
    img.tagline {
        position: relative;
        display: block;
        width: 540px;
        margin-left: 51%;
        max-width: 50%;
        z-index: 2;
    }
}


/** home featured menu **/
.featured {
    li {
        @include contentArea;
    }
    a {
        display: flex;
        width: 100%;
        padding: 5% 0px;
        box-sizing: border-box;
        span {
            display: block;
        }
        > div {
            width: 49%;
        }
    }
    .menu-icon { /* responsive square */
        position: relative;
        order: 1; /* push right */
        align-self: center;
        background-color: $light-grey;
        background-size: cover;
        background-position: center;
        max-width: 90%;
        border-radius: 50%;
        border: 4px solid $tertiary;
        box-sizing: border-box;
        transition: border-color 0.3s ease;
        &:before {
            content:"";
            display: block;
            padding-top: 100%;
        }
        &:hover {
            border-color: $secondary;
        }
    }
    .wrap {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-grow: 1;
        padding-right: 5%;
        box-sizing: border-box;
        > span {
            display: block;
            margin: 1em 0px;
            &.button {
                display: inline-block;
            }
        }
    }
    .title {
        color: $primary;
        font-weight: bold;
    }
    .subtitle {
        font-size: 2.6em;
        color: $highlight;
        font-weight: bold;
    }
    .page-teaser { /* flex margins don't collapse */
        margin: 5px 0px;
    }
}
figure.tagline {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 0px;
    &:before {
        @include tint_overlay;
        @include gradient($tertiary, $secondary);
        opacity: 0.7;
    }
    &:nth-of-type(even){
        &:before {
            @include gradient($secondary, $primary);
        }
    }
    .contentwrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-content: space-evenly;
        justify-content: space-between;
    }
    img {
        width: 49%;
        max-width: 100%;
        box-sizing: border-box;
    }
    .home-icon {
        width: 20%;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 10px auto;
        &:before { /* responsive square */
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%;
        }
    }
}

/*============ Page template ============*/
#content {
    padding: 3% 0px;
}

.two-col {
    &.subtitle {
        padding-right: 30px;
    }
    &.content {
        flex-grow: 1;
    }
}

/*============ Contact ============*/
.form-block { /* careers and contact blocks */
    padding: 2%;
    .two-col.text {
        padding-right: 30px;
    }
}
.contact-details {
    padding-top: 4px;
    h4, p, .opening-hours {
        margin: 18px 0px;
    }
}
.opening-hours {
    display: block;
}
.opening-days .opening-seperator {
    display: inline-block;
    padding: 0px 2px;
}
.opening-day.opening-to:after {
    content:":";
}

/*============ Careers ============*/
.t-careers {
    #content {
        padding-bottom: 0px;
    }
}
#careers-form {
    background-size: cover;
    background-position: center;
    .two-col.text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
            margin: 0px;
        }
    }
}

/*============ services index ============*/
.t-services {
    #content {
        padding: 0px;
    }
}
.item-body {
    padding-top: 4%;
}
.icon-wrapper {
    position: absolute;
    top: -50px;
    width: 100px;
    border-radius: 50%;
    @include gradient;
    box-sizing: border-box;
    &:before { /* responsive square */
        content:"";
        display: block;
        width: 100%;
        padding-top: 100%;
    }
    .item-icon {
        position: absolute;
        top: 25%;
        left: 25%;
        bottom: 25%;
        right: 25%;
        box-sizing: border-box;
        img {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            width: 100%;
            height: 100% !important;
            object-fit: contain;
            object-position: center;
        }
    }
}
.item-banner {
    position: relative;
    width: 100%;
    padding-top: 30%;
    source,
    img {
        @include fit-image;
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (min-width: 2200px){
    .banner-content {
        padding-top: 100px;
    }
}

@media only screen and (max-width: 1200px){
    .banners {
        .menu-wrap.main-menu {
            margin: 0 auto;
        }
    }
    .banner-content {
        text-align: center;
        > span {
            padding-left: 0px;
        }
        img.tagline {
            margin-left: 0px;
            margin: 0 auto;
        }
    }
    .banner-navigation-body {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 960px) {
/** mobile mode **/
    #mobile-menu {
        display:block;
    }
    .main-menu,
    .main-menu.menu-wrap {
        display:none;
    }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
/** header **/
    header {
        &.has-graphic {
            min-height: 300px;
            padding-bottom: 0px;
        }
        justify-content: flex-end;
        .contentwrap {
            padding-bottom: 0px;
        }
        a.logo {
            left: 10px;
            padding-right: 70px;
            box-sizing: border-box;
        }
    }
    .page-title {
        padding-left: 0px;
        margin-bottom: 20px;
    }
/** home **/
    .t-home header {
        height: 50vw;
        min-height: 400px;
        box-sizing: border-box;
    }
    .banners-home {
        height: 100%;
        min-height: 0px;
        box-sizing: border-box;
        .banner-stage {
            padding: 30px;
        }
        .contentwrap {
            display: none;
        }
        .banner-navigation {
            bottom: 0px;
        }
    }
    a.banner {
        padding-bottom: 90px; /* banner nav */
    }
    .banner-content {
        font-size: 2em;
        justify-content: center;
        text-align: center;
        height: 100%;
        padding-top: 30px;
        box-sizing: border-box;
        > span {
            padding-left: 0px;
        }
        img.tagline {
            width: 240px;
            max-width: 100%;
            margin: 0px auto;
        }
    }
/** footer **/
    footer {
        text-align: center;
    }
    .menu-wrap {
        justify-content: center;
    }
    .bykeo {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width: 760px) {
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
    }
/** stack cols **/
    /* page content + contact block */
    #content {
        padding: 0px;
    }
    .two-col {
        width: 100%;
        padding: 0px;
        &.subtitle {
            padding-top: 20px;
            padding-right: 0px;
        }
    }
    .two-col.text {
        padding-right: 0px;
        padding-bottom: 30px;
    }
    .form-block {
        .two-col,
        .two-col.text {
            padding-right: 0px;
            padding-bottom: 30px;
        }
        &#contact-block {
            h4.title,
            .two-col.text {
                text-align: center;
            }
        }
    }
    .t-contact .form-block#contact-block {
        h4.title,
        .two-col.text {
            text-align: left;
        }
    }
    /** services icons **/
    .services-icons {
        font-size: 2vw;
        nav li {
            padding: 2%;
            .title {
                margin-top: 1em;
            }
        }
    }
    /* careers block */
    .careers-block {
        padding: 0px;
        .two-col {
            text-align: center;
            padding-bottom: 0px;
        }
    }
    /* home featured menu */
    .featured {
        a {
            display: block;
            padding: 0px;
            > div {
                display: block;
                width: 100%;
            }
        }
        .menu-icon {
            width: 300px;
            margin: 0px auto;
            margin-bottom: 10px;
        }
        .wrap {
            display: block;
            text-align: center;
            padding-right: 0px;
            > span {
                margin: 20px 0px;
            }
        }
    }
}

@media only screen and (max-width: 600px){
/** services icons **/
    .services-icons {
        font-size: 16px;
        nav li {
            width: 49%;
            .menu-icon {
                display: inline-block;
                vertical-align: middle;
                width: 40px;
                padding-top: 40px;
            }
        }
    }
}

@media only screen and (max-width: 400px){
    header.has-graphic {
        min-height: 250px;
    }
/** normalise font-size **/
    .page-title {
        font-size: 2em;
    }
    header.has-graphic .page-title {
        padding-top: 30px; /* for logo + mobile icon */
    }
/** services icons **/
    .services-icons {
        .contentwrap {
            padding: 10px 30px;
        }
        nav li {
            width: 100%;
            padding: 10px 0px;
            a {
                position: relative;
                padding-left: 45px; /* icon */
                min-height: 40px;
            }
            .menu-icon {
                position: absolute;
                top: 0px;
                left: 0px;
            }
            .title {
                width: 100%;
                height: 100%;
                padding: 5px;
                box-sizing: border-box;
                margin: 0px;
            }
        }
    }
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}